<section>
  <div *ngIf="!isCartLoaded$.value || !isCharityLoaded$ .value" class="container">
    <div class="row">
      <div class="row ml-2 pt-1">
        <div class="col-4 col-sm-4 col-md-3 m-1">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 40,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="row ml-2 pt-1">
        <div class="col-5 col-sm-5 col-md-4 m-1">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="row p-8">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 60,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="row p-8">
        <div class="col-md-3">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-3">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-3">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-3">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-10 col-sm-10 col-md-9 m-1">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(this.isCartLoaded$  | async )&& (isCharityLoaded$ | async)">
    <div class="col-lg-8">
      <ea-order-steps *ngIf="!orderId" [step]="'Payment'"></ea-order-steps>
    </div>

    <div class="row contint" *ngIf="!orderId; else thankYou">
      <div class="col-lg-8">
        <div class="col-lg-12">
          <div class="pt-3">
              <ea-checkout-addresses
               class="w-100"
                [isBulling]="true"
                [bullingAddress]="bullingAddress"
                [newBillingAddress]="newBullingAddress"
                (changes)="changeAddress($event)"
              >
              </ea-checkout-addresses>
              <br class="space">
            <ea-charity-choose
              [charities]="charities"
              [disability]="disability"
              [user]="user"
              [selectedDedisability]="selectedDedisability"
              [selected]="selected"
              (changeDisabilityEvent)="changeDisability($event)"
              (onOptionsChangedEvent)="onOptionsChanged($event)"
            ></ea-charity-choose>
            <br class="space">


            <div>
              <div
                class="p-4 bg-theme-surface text-theme-on-surface mb-3 font-title mb-4 address-card cart-div"

              >
              <hr class="mobile">

                <h1 class="font-bold">Your saved payment cards</h1>
                <div
                  *ngFor="let option of cards"
                  [ngClass]="
                    option != slectdCardoption
                      ? 'col address-option'
                      : 'col address-option div-active'
                  "
                >
                  <div class="contaner justify-content-between row">
                    <div class="col-1 mt-2">
                      <label>
                        <input
                          class="form-radio-input"
                          type="radio"
                          [(ngModel)]="slectdCardoption"
                          [value]="option"
                          name="card"
                          (click)="selectedCardId(option)"
                          id="{{option.paymentMethodId}}"
                        />
                      </label>
                    </div>
                    <div class="col-7 justify-content-between row">
                      <div class="col-lg-3">
                        <img
                          data-src="assets/images/cardsIcons/{{
                            option.type
                          }}.png"
                          style="width: 90px"
                          alt="Card Type"
                        />
                      </div>
                      <div class="col-lg-9 font-bold type">
                        <h2>
                          {{ option.type }}
                        </h2>
                      </div>
                    </div>
                    <div class="col-4">
                      <h2><label for="{{option.paymentMethodId}}">**** - {{ option.last4 }}</label></h2>
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="
                    !thereAreCards || displayNewCard
                      ? 'col address-option div-active'
                      : 'col address-option'
                  "
                >
                  <div class="contaner justify-content-between row">
                    <div class="col-1 mt-2">
                      <label>
                        <input
                          class="form-radio-input"
                          type="radio"
                          [(ngModel)]="!thereAreCards || displayNewCard"
                          name="card"
                          (click)="show()"
                          [value]="true"
                          id="newCard"
                        />
                      </label>
                    </div>
                    <div class="col-11 justify-content-between row">
                      <h2><label for="newCard"> Add a new credit/debit card</label></h2>
                    </div>
                  </div>
                </div>
                <form
                  [formGroup]="form"
                  class="mt-4"
                  (ngSubmit)="makePayment()"
                >
                  <div *ngIf="!thereAreCards || displayNewCard">
                    <h1 class="font-bold">Enter new payment card details</h1>
                    <div *ngIf="!thereAreCards || displayNewCard">
                      <div class="row w-100">
                        <div class="col-10">
                          <ea-form-field
                            class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column w-100 row"
                            label="Card Number"
                            name="cardNumber"
                            alt="Card Number input"
                            [form]="form"
                          ></ea-form-field>
                        </div>
                        <div class="col-2 mt-4">
                          <img
                            src="assets/images/cardsIcons/cardNumberIcon.svg"
                            style="width: 60px; margin-top: 30px"
                            alt="Card number"
                          />
                        </div>
                      </div>
                      <div class="row w-100">
                        <div class="col-10">
                          <ea-form-field
                            class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column row w-100"
                            label="Expiry date MM/YY"
                            name="expiryMonth"
                            alt="Expiry Month input"
                            [form]="form"
                            [expiredDate]="true"
                          ></ea-form-field>
                        </div>
                        <div class="col-2 mt-4">
                          <img
                            src="assets/images/cardsIcons/cardExpiryDate.svg"
                            style="width: 60px; margin-top: 30px"
                            alt="Card number"
                          />
                        </div>
                      </div>
                      <div class="row w-100">
                        <div class="col-10">
                          <ea-form-field
                            class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column row w-100"
                            label="CVC"
                            name="cvc"
                            alt="cvc input"
                            [form]="form"
                          ></ea-form-field>
                        </div>
                        <div class="col-2 mt-4">
                          <img
                            src="assets/images/cardsIcons/CVC.svg"
                            style="width: 60px; margin-top: 30px"
                            alt="Card number"
                          />
                        </div>
                      </div>
                      <div class="row w-100">
                        <div class="col-12">
                          <ea-form-field
                            class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column row w-100"
                            label="Name on card"
                            name="fullName"
                            alt="Name on card"
                            [form]="form"
                          ></ea-form-field>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-3 text-right"
                      *ngIf="!thereAreCards || displayNewCard"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input focus-ring"
                        [checked]="saveCart"
                        style="width: 20px; height: 20px"
                        (change)="saveCart = !saveCart"
                        aria-label="Remember me"
                        alt="save card checkbox"
                      />
                      <label class="m-1"> Save card</label>
                    </div>
                  </div>
                  <div class="row">
                    <div>
                      <ng-container *ngIf="error$ | async as error">
                        <div class="error text-red-500 font-bold">
                          {{ error }}
                        </div>
                      </ng-container>
                      <button
                        type="submit"
                        title="Pay now"
                        value="Pay now"
                        class="ea-button ea-button-primary col-lg-12 col-xl-12 focus-ring w-100"
                        [disabled]="form.invalid || (isLoading$ | async)"
                        *ngIf="cart"
                      >
                      <span class="material-icons mt-1 mr-1">lock</span>
                      <p class="text-theme-on-surface" style="color: white;">
                      <strong>Pay {{ totalAmount | currency: cart.currency }} now </strong>
                      </p>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 justify-content-end discktop">
        <div class="subtotal-content">
          <div
          class="total-card"
        >
          <div class="row ps-2 pe-2 cart-totals">
            <div *ngIf="cart">
              <div class="row">
                <div class="col-lg-8">Subtotal</div>

                <div class="col-lg-4">
                  <div
                    class="float-right mb-3 text-right"
                  >
                    <p class="text-theme-on-surface">
                      {{
                        cart.subTotal - estimatedShippingAmount
                          | currency: cart.currency
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">Estimated shipping</div>

                <div class="col-lg-4" >
                  <div class="float-right text-theme-on-surface mb-3 text-right">
                  <p class="text-theme-on-surface">  {{ estimatedShippingAmount | currency: cart.currency }}</p>
                  </div>
                </div>
              </div>
              <hr class="text-light-gray" />
              <div class="row mt-1">
                <div class="col-lg-8">
                  <strong>Total to pay</strong>
                </div>
                <div class="col-lg-4">
                  <div class="float-right mb-3 text-right">
                    <p class="text-theme-on-surface font-bold">
                      {{ cart.totalAmount | currency: cart.currency }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">VAT</div>
                <div class="col-lg-4">
                  <div class="float-right mb-3 text-right">
                    <p class="text-theme-on-surface">
                      {{ totalTax | currency: cart.currency }}
                    </p>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="error$ | async as error">
                <div class="error text-red-500 font-bold">{{ error }}</div>
              </ng-container>
              <button
                type="submit"
                title="Pay now"
                value="Pay now"
                (click)="makePayment()"
                class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 focus-ring"
                [disabled]="form.invalid || (isLoading$ | async)"
              >
                <span class="material-icons mt-1 mr-1">lock</span>
              <p class="text-theme-on-surface" style="color: white;">
             <strong> Pay {{ totalAmount | currency: cart.currency }} now </strong>
              </p>
              </button>
            </div>
            </div>
          </div>
          <div style="margin-top: 201px;">
            <ea-info-card></ea-info-card>
          </div>

        </div>
      </div>

      <div class="subtotal-content-mobile col-lg-12">
        <div class="">
          <div *ngIf="cart">
            <div class="row mt-1">
              <div class="col-7">Subtotal</div>

              <div class="col-4">
                <div
                  class="float-right text-right"
                  *ngIf="estimatedShippingAmount > 0"
                >
                  <p class="text-theme-on-surface">
                    {{
                      cart.subTotal - estimatedShippingAmount
                        | currency: cart.currency
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-7">Estimated Shipping</div>

              <div class="col-4" *ngIf="estimatedShippingAmount">
                <div class="float-right text-right">
                  {{ estimatedShippingAmount | currency: cart.currency }}
                </div>
              </div>
            </div>
            <hr class="text-light-gray" />
            <div class="row mt-1">
              <div class="col-7">
                <strong>Total to pay</strong>
              </div>
              <div class="col-4">
                <div class="float-right text-right">
                  <p class="text-theme-on-surface font-bold">
                    {{ cart.totalAmount | currency: cart.currency }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-7">VAT</div>
              <div class="col-4">
                <div class="float-righ text-right">
                  <p class="text-theme-on-surface">
                    {{ totalTax | currency: cart.currency }}
                  </p>
                </div>
              </div>
            </div>

            <ng-container *ngIf="error$ | async as error">
              <div class="error text-red-500 font-bold">{{ error }}</div>
            </ng-container>
            <button
              type="submit"
              title="Pay now"
              value="Pay now"
              (click)="makePayment()"
              class="ea-button ea-button-primary col-12 focus-ring"
              [disabled]="form.invalid || (isLoading$ | async)"
            >
              <span class="material-icons mt-1 mr-1">lock</span>
              <p class="text-theme-on-surface" style="color: white;">
                <strong> Pay {{ totalAmount | currency: cart.currency }} now </strong>
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #thankYou>
    <div
      class="card border-dark-gray-1px font-title bg-theme-surface focus-outline text-theme-on-surface"
    >
      <h2>
        Thank you for your order! Your order id is
        <a
          class="font-bold"
          [routerLink]="['/account/orders/order-details/', orderNumber]"
        >
          {{ orderNumber }}
        </a>
      </h2>
    </div>
  </ng-template>
</section>
