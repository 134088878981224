import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CookieService, selectAuthState } from '@ea/auth';
import {
  AddItemRequest,
  CartFacade,
  CartResponse,
  cartSelectors,
} from '@ea/cart';
import { BaseComponent } from '@ea/components';
import { LocalStorageService, ProductService } from '@ea/services';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { productAddItem } from 'src/app/models/productDetails.model';
import { DataLayerService } from 'src/app/services/shared/WindowReference/DataLayerService';
import environment from 'src/environments/environment';
import { CartProduct, CartTracking } from '../../models';

declare const SR: any;

@Component({
  selector: 'ea-product-add-to-basket',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,MatIconModule],
  templateUrl: './product-add-to-basket.component.html',
  styleUrls: ['./product-add-to-basket.component.scss'],
})
export class ProductAddToBasketComponent
  extends BaseComponent
  implements OnInit
{
  public get cart() {
    return this.cart$.value;
  }

  @Input() product?: productAddItem;
  @Input() eventModel?: any;
  @Input() breadcrumbsString?: string;
  // cart: Cart;
  form?: FormGroup;
  quantity: number = 1;
  isAuthenticated$?: Observable<boolean>;
  cart$ = new BehaviorSubject<CartResponse | undefined>(undefined);

  productsInTheCartCount$?: Observable<number>;

  ngOnInit(): void {
    if (this.product) {
      this.form = this.fb.group({
        id: [this.product?.itemId],
        quantity: 1,
      });
    }

    this.productsInTheCartCount$ = this.store
      .select(cartSelectors.getCart)
      .pipe(
        tap((cart) => {
          this.cart$.next(cart);
        }),
        switchMap((cart) =>
          of(
            cart.items?.find((x) => x.itemId == this.product?.itemId + '')
              ?.quantity ?? 1
          )
        ),
        tap((qty) => {
          // this.form?.controls['quantity'].setValue(qty);
        })
      );
  }

  addToBasket() {
    this.toggleDisable(true);
    if (this.cart && this.product && this.form) {
      const formValue = this.form.getRawValue();
      const request: AddItemRequest = {
        cartId: this.cart.cartId,
        name: this.cart.metadata.name,
        items: [
          {
            itemId: this.product.itemId,
            type: 'WEB_SHIP',
            priceListId: environment.fabricPriceListId.priceListId,
            price: {
              amount: this.product.price.baseValue,
              currency: this.product.price.currency,
            },
            quantity: formValue.quantity,
            extra: {
              parentName: {
                attributeId: 'parent_Name',
                name: 'parent_Name',
                description: this.product.parentSku,
                type: 'TEXT',
                value: this.product.parentName,
                price: 0,
              },
              breadcrumbsString: {
                attributeId: 'breadcrumbsString',
                name: 'breadcrumbsString',
                description: 'breadcrumbsString',
                type: 'TEXT',
                value: this.breadcrumbsString!,
                price: 0,
              },
              orginalPrice: {
                attributeId: 'orginalPrice',
                name: 'orginalPrice',
                description: 'orginalPrice',
                type: 'TEXT',
                value: `${this.product.price.baseValue}`,
                price: this.product.price.baseValue
              }
            },
          },
        ],
      };

      this.facade.addItem(request);
      this.toggleDisable(false);
      this.eventModel.quantity = formValue.quantity;
      this.eventModel.event = 'add_to_cart';
      this.storageService.setItem(
        this.product.itemId.toString(),
        this.breadcrumbsString
      );
      this.dataLayer.SendEvent(this.eventModel);
      this.trackSyneriseCart();
    }
  }
  onlyAllowNumbers(event: KeyboardEvent): boolean {
    const charCode = event.charCode;
    if (charCode !== 8 && (charCode === 0 || charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  changeQuantity() {
    // Get the specific form control's value
    let formControlValue = this.form!.get('quantity')?.value;
  
    // Check if the value is less than 1
    if (formControlValue < 1) {
      // Set the form control's value to 1
      this.form!.get('quantity')?.setValue(1);
    }
  }
  increaseQuantity() {
    this.quantity++;
    this.updateQuantity();
  }
  
  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
      this.updateQuantity();
    }
  }
  
  updateQuantity() {
    // Update the form control with the new value
    this.form!.get('quantity')?.setValue(this.quantity);
  }

  trackSyneriseCart(){
    this.cart$?.subscribe((userCart: CartResponse | undefined) => {      
      const cartTracking: CartTracking = {} as CartTracking;
      cartTracking.products = [];
      userCart?.items?.forEach((cartItem) => {
        let brandName = "";
        const brandNameObj = cartItem.attributes.filter(x => x.name == "brand")[0];
        if(brandNameObj){
          brandName = brandNameObj.value;
        }

        const productCategory = cartItem.extra?.breadcrumbsString?.value;

        const cartProduct: CartProduct = {
          sku: cartItem.sku,
          quantity: cartItem.quantity,
          brand: brandName,
          category: productCategory,
          unitPrice: cartItem.unitPrice,
          totalPrice: cartItem.totalPrice
        }
        cartTracking.products.push(cartProduct);
      });

      cartTracking.totalAmount = userCart?.totalAmount || 0;
      cartTracking.totalQuantity = userCart?.totalItems || 0;
      cartTracking.itemIds = cartTracking.products.map(({ sku } : {sku:string}) => sku);
      cartTracking.client = this.cookies.get('_snrs_uuid') || '';
      
      //Synerise tracking for abandoned Carts
      SR.event.trackCustomEvent('cart.status', cartTracking, 'CartStatus');
    });
  }
  
  constructor(
    private readonly fb: FormBuilder,
    private store: Store,
    private readonly facade: CartFacade,
    public productService: ProductService,
    private dataLayer: DataLayerService,
    private storageService: LocalStorageService,
    private readonly cookies: CookieService
  ) {
    super();
    //  this.cart = this.service.get();
    this.isAuthenticated$ = this.store
      .select(selectAuthState)
      .pipe(map((authStatus) => authStatus.isAuthenticated));
  }


  
}
